import React, { useState } from 'react';
import {
  Table, TableRow, TableCell, TableHead, TableBody, Chip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import EditIcon from '@mui/icons-material/Check';
import { useEffectAsync } from '../reactHelper';
import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { formatDistance, formatSpeed } from '../common/util/formatter';
import { useAttributePreference } from '../common/util/preferences';
import { useTranslation } from '../common/components/LocalizationProvider';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import CollectionFab from './components/CollectionFab';
import CollectionActions from './components/CollectionActions';
import TableShimmer from '../common/components/TableShimmer';
import SearchHeader, { filterByKeyword } from './components/SearchHeader';

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: '1%',
    paddingRight: theme.spacing(1),
  },
}));

const maintenanceStatusMap = {
  required: {
    completed: {
      text: 'maintenanceCompleted',
      color: 'success',
    },
    notCompleted: {
      text: 'maintenanceRequired',
      color: 'error',
    },
  },
  notRequired: {
    completed: {
      text: 'maintenanceCompleted',
      color: 'success',
    },
    notCompleted: {
      text: 'maintenancePending',
      color: 'primary',
    },
  },
};

const MaintenacesPage = () => {
  const classes = useStyles();
  const t = useTranslation();

  const positionAttributes = usePositionAttributes(t);

  const [timestamp, setTimestamp] = useState(Date.now());
  const [items, setItems] = useState([]);
  const [devices, setDevices] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const speedUnit = useAttributePreference('speedUnit');
  const distanceUnit = useAttributePreference('distanceUnit');

  useEffectAsync(async () => {
    setLoading(true);
    try {
      const maintenanceResponse = await fetch('/api/maintenance');
      const deviceResponse = await fetch('/api/devices');

      if (maintenanceResponse.ok) {
        setItems(await maintenanceResponse.json());
      } else {
        throw Error(await maintenanceResponse.text());
      }

      if (deviceResponse.ok) {
        const data = await deviceResponse.json();

        setDevices(data.reduce((acc, device) => ({
          ...acc,
          [device.id]: device.name,
        }), {}));
      } else {
        throw Error(await deviceResponse.text());
      }
    } finally {
      setLoading(false);
    }
  }, [timestamp]);

  const convertAttribute = (key, value) => {
    const attribute = positionAttributes[key];
    if (attribute && attribute.dataType) {
      switch (attribute.dataType) {
        case 'speed':
          return formatSpeed(value, speedUnit, t);
        case 'distance':
          return formatDistance(value, distanceUnit, t);
        default:
          return value;
      }
    }

    return value;
  };

  const handleCompletedClick = async (itemId) => {
    const item = items?.find((item) => item.id === itemId);

    if (item) {
      const itemToSet = {
        ...item,
        completed: true,
      };

      const response = await fetch(`/api/maintenance/${itemToSet.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(itemToSet),
      });

      if (response.ok) {
        window.location.reload(false);
      } else {
        throw Error(await response.text());
      }
    }
  };

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'sharedMaintenance']}>
      <SearchHeader keyword={searchKeyword} setKeyword={setSearchKeyword} />
      <Table style={{ marginBottom: 80 }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('sharedName')}</TableCell>
            <TableCell>{t('sharedType')}</TableCell>
            <TableCell>{t('maintenanceStart')}</TableCell>
            <TableCell>{t('sharedDevice')}</TableCell>
            <TableCell>{t('maintenanceStatus')}</TableCell>
            <TableCell className={classes.columnAction} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading ? items.filter(filterByKeyword(searchKeyword)).map((item) => {
            const maintenanceStatus = maintenanceStatusMap[item.required ? 'required' : 'notRequired'][item.completed ? 'completed' : 'notCompleted'];

            return (
              <TableRow key={item.id}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item ? t(`position${item.type[0].toUpperCase() + item.type.slice(1)}`) ?? '' : ''}</TableCell>
                <TableCell>{convertAttribute(item.type, item.start)}</TableCell>
                <TableCell>{devices[item.deviceId]}</TableCell>
                <TableCell><Chip variant="outlined" color={maintenanceStatus.color} label={t(maintenanceStatus.text)} /></TableCell>
                <TableCell className={classes.columnAction} padding="none" align="right">
                  <CollectionActions
                    rightAlign
                    itemId={item.id}
                    editPath="/settings/maintenance"
                    endpoint="maintenance"
                    setTimestamp={setTimestamp}
                    customActions={!item.completed ? [{
                      key: 'mark-as-completed-action',
                      itemId: item.id,
                      title: t('sharedMarkAsCompleted'),
                      icon: <EditIcon fontSize="small" />,
                      handler: handleCompletedClick,
                    }] : undefined}
                  />
                </TableCell>
              </TableRow>
            );
          }) : (<TableShimmer columns={6} endAction />)}
        </TableBody>
      </Table>
      <CollectionFab editPath="/settings/maintenance" />
    </PageLayout>
  );
};

export default MaintenacesPage;
