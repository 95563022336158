import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkField from '../common/components/LinkField';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';
import { formatNotificationTitle } from '../common/util/formatter';
import PageLayout from '../common/components/PageLayout';
import useFeatures from '../common/util/useFeatures';
import SelectField from '../common/components/SelectField';
import { useEffectAsync } from '../reactHelper';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
}));

const DeviceConnectionsPage = () => {
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(true);

  const classes = useStyles();
  const t = useTranslation();

  const { id } = useParams();

  const features = useFeatures();

  const handleGroupChange = async (groupId) => {
    try {
      setItem((prevState) => ({ ...prevState, groupId }));

      if (groupId !== undefined) {
        const response = await fetch(`/api/devices/${id}/group/${groupId}`);

        if (!response.ok) {
          throw Error(await response.text());
        }
      }
    } catch (error) {
      throw Error(error);
    }
  };

  useEffectAsync(async () => {
    setLoading(true);

    try {
      const response = await fetch(`/api/devices?id=${id}`);
      if (response.ok) {
        const data = await response.json();

        if (data?.[0]) {
          setItem(data[0]);
        }
      } else {
        throw Error(await response.text());
      }
    } finally {
      setLoading(false);
    }
  }, [id]);

  return (
    <PageLayout
      menu={<SettingsMenu />}
      breadcrumbs={['settingsTitle', 'sharedDevice', 'sharedConnections']}
    >
      <Container maxWidth="xs" className={classes.container}>
        {!loading && (
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedConnections')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <LinkField
                endpointAll="/api/geofences"
                endpointLinked={`/api/geofences?deviceId=${id}`}
                baseId={id}
                keyBase="deviceId"
                keyLink="geofenceId"
                label={t('sharedGeofences')}
              />
              <LinkField
                endpointAll="/api/notifications"
                endpointLinked={`/api/notifications?deviceId=${id}`}
                baseId={id}
                keyBase="deviceId"
                keyLink="notificationId"
                titleGetter={(it) => formatNotificationTitle(t, it)}
                label={t('sharedNotifications')}
              />
              {!features.disableDrivers && (
                <LinkField
                  endpointAll="/api/drivers"
                  endpointLinked={`/api/drivers?deviceId=${id}`}
                  baseId={id}
                  keyBase="deviceId"
                  keyLink="driverId"
                  label={t('sharedDrivers')}
                />
              )}
              {!features.disableComputedAttributes && (
                <LinkField
                  endpointAll="/api/attributes/computed"
                  endpointLinked={`/api/attributes/computed?deviceId=${id}`}
                  baseId={id}
                  keyBase="deviceId"
                  keyLink="attributeId"
                  titleGetter={(it) => it.description}
                  label={t('sharedComputedAttributes')}
                />
              )}
              <LinkField
                endpointAll="/api/commands"
                endpointLinked={`/api/commands?deviceId=${id}`}
                baseId={id}
                keyBase="deviceId"
                keyLink="commandId"
                titleGetter={(it) => it.description}
                label={t('sharedSavedCommands')}
              />
              {!features.disableMaintenance && (
                <LinkField
                  endpointAll="/api/maintenance"
                  endpointLinked={`/api/maintenance?deviceId=${id}`}
                  baseId={id}
                  keyBase="deviceId"
                  keyLink="maintenanceId"
                  label={t('sharedMaintenance')}
                />
              )}
              <SelectField
                value={item?.groupId || 0}
                onChange={(event) => handleGroupChange(Number(event.target.value))}
                endpoint="/api/groups"
                label={t('groupParent')}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </Container>
    </PageLayout>
  );
};

export default DeviceConnectionsPage;
