import React from 'react';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Logo } from '../resources/images/logo.svg';
import { ReactComponent as LightLogo } from '../resources/images/light-logo.svg';

const LogoImage = ({ light = false, height = '120px' }) => {
  const useStyles = makeStyles(() => ({
    image: {
      alignSelf: 'center',
      maxWidth: '240px',
      maxHeight: height,
      width: 'auto',
      height: 'auto',
    },
  }));

  const classes = useStyles();

  if (light) {
    return (<LightLogo className={classes.image} />);
  }

  return (
    <Logo className={classes.image} />
  );
};

export default LogoImage;
