import {
  FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import React, { useState } from 'react';
import { useEffectAsync } from '../../reactHelper';
import { useAdministrator } from '../util/permissions';

const SelectField = ({
  filterTypes = false,
  label,
  fullWidth,
  multiple,
  value,
  emptyValue = 0,
  emptyTitle = '\u00a0',
  onChange,
  endpoint,
  data,
  keyGetter = (item) => item.id,
  titleGetter = (item) => item.name,
}) => {
  const [items, setItems] = useState(data);

  const admin = useAdministrator();

  useEffectAsync(async () => {
    if (endpoint) {
      const response = await fetch(endpoint);

      if (response.ok) {
        const responseData = await response.json();

        if (filterTypes && !admin) {
          const filteredData = responseData.filter((item) => item?.type !== 'commandResult' &&
            item?.type !== 'deviceInactive' &&
            item?.type !== 'textMessage' &&
            item?.type !== 'driverChanged' &&
            item?.type !== 'media');

          setItems(filteredData);
        } else {
          setItems(responseData);
        }
      } else {
        throw Error(await response.text());
      }
    }
  }, [filterTypes]);

  if (items) {
    return (
      <FormControl fullWidth={fullWidth}>
        <InputLabel>{label}</InputLabel>
        <Select
          MenuProps={{
            style: {
              maxHeight: 300,
            },
          }}
          label={label}
          multiple={multiple}
          value={value}
          onChange={onChange}
        >
          {!multiple && emptyValue !== null && (
            <MenuItem value={emptyValue}>{emptyTitle}</MenuItem>
          )}
          {items.map((item) => (
            <MenuItem key={keyGetter(item)} value={keyGetter(item)}>{titleGetter(item)}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  return null;
};

export default SelectField;
