import React from 'react';
import {
  Card,
  CardContent,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import PositionValue from '../common/components/PositionValue';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useAdministrator } from '../common/util/permissions';
import { prefixString } from '../common/util/stringUtils';
import usePositionAttributes from '../common/attributes/usePositionAttributes';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 'auto',
    maxHeight: 'calc(100% - 50px)',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: 420,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1, 0, 2),
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    overflowY: 'scroll',
  },
  table: {
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cell: {
    borderBottom: 'none',
  },
}));

const StatusRow = ({ name, content }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell className={classes.cell}>
        <Typography variant="body2">{name}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body2" color="textSecondary">{content}</Typography>
      </TableCell>
    </TableRow>
  );
};

const PositionModal = ({ open, handleClose, position }) => {
  const classes = useStyles();

  const t = useTranslation();

  const admin = useAdministrator();

  const positionAttributes = usePositionAttributes(t);

  const deviceName = useSelector((state) => {
    if (position) {
      const device = state.devices.items[position.deviceId];
      if (device) {
        return device.name;
      }
    }
    return null;
  });

  if (!position) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Card elevation={3} className={classes.root}>
        <div className={classes.header}>
          <Typography variant="body2" color="textSecondary">
            {t('sharedAttributes')}
          </Typography>
          <IconButton
            size="small"
            onClick={handleClose}
            onTouchStart={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <CardContent className={classes.content}>
          <Table size="small" classes={{ root: classes.table }}>
            <StatusRow
              name={t('reportDevice')}
              content={deviceName}
            />
            <TableBody>
              {Object.getOwnPropertyNames(position).filter((it) => it !== 'attributes').map((property) => {
                if (!admin && (
                  property === 'network' ||
                  property === 'id' ||
                  property === 'deviceId' ||
                  property === 'protocol' ||
                  property === 'outdated' ||
                  property === 'accuracy'
                )) {
                  return null;
                }

                return (
                  <StatusRow
                    key={property}
                    name={t(prefixString('position', property)) || (positionAttributes.hasOwnProperty(property) ? positionAttributes[property].name : property)}
                    content={(
                      <PositionValue position={position} property={property} />
                    )}
                  />
                );
              })}
              {Object.getOwnPropertyNames(position.attributes).map((attribute) => {
                if (!admin && (
                  attribute === 'priority' ||
                  attribute === 'io24'
                )) {
                  return null;
                }

                return (
                  <StatusRow
                    key={attribute}
                    name={t(prefixString('position', attribute)) || t(prefixString('device', attribute)) || (positionAttributes.hasOwnProperty(attribute) ? positionAttributes[attribute].name : attribute)}
                    content={(
                      <PositionValue position={position} attribute={attribute} />
                    )}
                  />
                );
              })}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default PositionModal;
